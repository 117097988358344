<div class="container">
    <h2 class="pt-4">Cómo funciona </h2>
    <span> Una descripción gráfica del Curso por Extensión</span>
    <section id="how_it_works">
        <label>Abajo se encuentra una descripción gráfica de como funciona el Curso por Extensión</label>
        <!-- Paso 1 -->
        <div class="row">
            <div class="col-lg-4 pt-4">
                <h4>Paso 1</h4>
                <p>
                    Nosotros entregamos todos los cursos de libros y conferencias de Los Fundamentos, los Congresos y
                    los
                    ACCs en línea. Al hacer esto podemos ayudarle a pasar a través de los materiales de la Edad de Oro
                    del Conocimiento más rápido y con todas las ganancias posibles sin importar donde viva.
                </p>
            </div>
            <img class="col-8 pt-4 " src="https://cursosporextension.com/assets/image/step1.jpg" alt="">
        </div>
        <hr>
        <!-- Paso 2 -->
        <div class="row">
            <div class="col-lg-4 pt-4">
                <h4>Paso 2</h4>
                <p>
                    Cuando esté listo para comenzar su Curso de Extensión, primero vaya a nuestra tienda en línea,
                    seleccione el curso que desea tomar y luego el botón que dice "registrarse para comenzar este
                    curso".
                </p>
            </div>
            <img class="col-8 pt-4 " src="https://cursosporextension.com/assets/image/step2.png" alt="">
        </div>
        <hr>
        <!-- Paso 3 -->
        <div class="row">
            <div class="col-lg-4 pt-4">
                <h4>Paso 3</h4>
                <p>
                    Una vez que presione ese botón, se le va a llevar a nuestra página de registro donde usted va a
                    poder crear su cuenta y podrá registrarse para el curso que desea.
                </p>
            </div>
            <img class="col-8 pt-4 " src="https://cursosporextension.com/assets/image/step3.png" alt="">
        </div>
        <hr>
        <!-- Paso 4 -->
        <div class="row">
            <div class="col-lg-4 pt-4">
                <h4>Paso 4</h4>
                <p>
                    Una vez registrado será redirigido al curso seleccionado y un botón con el texto de "Comprar Curso"
                    dando un clic al mismo.
                </p>
            </div>
            <img class="col-8 pt-4 " src="https://cursosporextension.com/assets/image/step4.png" alt="">
        </div>
        <hr>
        <!-- Paso 5 -->
        <div class="row">
            <div class="col-lg-4 pt-4">
                <h4>Paso 5</h4>
                <p>
                    Después de realizar la compra de forma exitosa será redirigido a la página de "Mis Cursos" donde
                    podrá observar el curso adquirido, las lecciones desglosadas y los cursos terminados.
                </p>
            </div>
            <img class="col-8 pt-4 " src="https://cursosporextension.com/assets/image/step5.png" alt="">
        </div>
        <hr>
        <!-- Paso 6 -->
        <div class="row">
            <div class="col-lg-4 pt-4">
                <h4>Paso 6</h4>
                <p>
                    Una vez seleccionando una lección se desglosaran las preguntas correspondientes a contestar.
                </p>
            </div>
            <img class="col-8 pt-4 " src="https://cursosporextension.com/assets/image/step6.png" alt="">
        </div>
        <hr>
        <!-- Paso 7 -->
        <div class="row">
            <div class="col-lg-4 pt-4">
                <h4>Paso 7</h4>
                <p>
                    Después de enviar las respuestas un supervisor calificará las respuestas notificándole por medio de
                    correo electrónico el resultado.
                </p>
            </div>
            <img class="col-8 pt-4 " src="https://cursosporextension.com/assets/image/step7.png" alt="">
        </div>
        <hr>
        <!-- Paso 8 -->
        <div class="row">
            <div class="col-lg-4 pt-4">
                <h4>Paso 8</h4>
                <p>
                    Una vez que haya completado todas sus asignaciones, cada una con calificación de 100%, usted va a
                    recibir un certificado por la completación satisfactoria de su curso. Cualquier curso que usted tome
                    con nosotros será acreditado a cualquier entrenamiento que vaya a hacer en el futuro.
                </p>
            </div>
            <img class="col-8 pt-4 " src="https://cursosporextension.com/assets/image/step8.jpg" alt="">
        </div>
        <hr>

        <a [routerLink]="['/Sign-Up']">Presione aquí para entrar a nuestra tienda en línea y comenzar su ruta al conocimiento.</a>

    </section>
</div>