<div class="container">
  <h2 class="pt-4">Cursos de Extensión </h2>
  <span> Los Fundamentos </span>
  <section id="how_it_works">
    <div class="row">
      <div class="col-lg-3 pt-4 d-flex justify-content-between " *ngFor="let course of courses">
        <!--<a [routerLink]="['/Course',course._id ]">
                <img src="{{course.image}}">
                <br>
               <p class="text-dark text-center">{{course.name}}</p>
              
            </a>
        -->
        <div class="card">
          <a [routerLink]="['/Course',course._id ]" class="td-none">
            <img src="{{course.image}}">
            <div class="card-body">
              <h4 class="card-title text-dark">{{ textFormat(course.name) }}</h4>
              <p style="text-overflow: ellipsis; overflow: hidden; white-space: initial; -webkit-line-clamp: 5;display: -webkit-box;   -webkit-box-orient: vertical;"
                class="card-text text-dark">
                {{ course.description }}
              </p>
              ...ver más
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</div>