<div class="container">
  <h2 class="pt-4">Usuario: {{ user.name }} {{ user.lastname }} </h2>
  <h4>Curso: {{ user.actualCourse }}</h4>
  <h4>Leccion No. {{ numberOfLesson }} </h4>
  <section id="how_it_works">
    <div class="row" *ngFor="let question of questions">
      <div class="col-8">
        <p class="mb-1 mt-3"><strong>{{ question.question }}</strong></p>
        <p>{{ question.answer }}</p>
      </div>
      <div class="col-4">
        <div class="d-flex justify-content-end mt-3" *ngIf="question.qualified != true">
          <button type="button" class="btn btn-outline-success m-1"
            (click)="qualify(question._id, true)">Correcto</button>
          <button type="button" class="btn btn-outline-danger m-1"
            (click)="qualify(question._id, false)">Incorrecto</button>
        </div>
        <div class="d-flex justify-content-end mt-3" *ngIf="question.qualified == true">
          <span class="mr-2" *ngIf="question.status == 'Completado'">
            <strong class="text-success">Calificado</strong>
          </span>
          <textarea class="form-control" placeholder="Observaciones" name="observations"
            [(ngModel)]="question.observations" *ngIf="question.status == 'Corregir'"></textarea>
        </div>
      </div>

    </div>

    <button type="button" class="btn btn-outline-primary mt-3" (click)="save()">Guardar</button>
  </section>
</div>