import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'question-answer',
  template: `
  <div class="border-2 border-red-500 m-3">
    <h6>
     {{ question }}
    </h6>
    <textarea
      [(ngModel)]="answerValue"
      (change)="onTextAreaChange()"
      rows="3"
      class="form-control"
      required
      
    ></textarea>
  </div>
`,
  styleUrls: ['./question-answer.component.css']
})
export class QuestionAnswerComponent  {

  @Input() questionIndex;
  @Input() question;
  @Output() answer = new EventEmitter<string>();
  answerValue = "";

  onTextAreaChange() {
    this.answer.emit(this.answerValue);
  }
}


  


