<div class="container">
  <h2 class="pt-4">Curso : {{ userDetails.actualCourse }} </h2>
  <h4> Leccion No. {{ numberOfLesson }} </h4>
  <section id="how_it_works">

    <form (submit)="sendAnswers()" *ngIf="!displayLesson" id="questions">
      <div class="row" *ngFor="let questionString of Questions.question; index as idx;">
        <div class="col-lg-4 p-4">{{ observation(questionString) }}</div>
        <div class="col-lg-8">

          <question-answer questionIndex="{{idx + 1}}" question="{{questionString}}"
            (answer)="onChildAnswer(idx, $event)">
          </question-answer>

        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button type="submit" class="pt-2 btn btn-outline-secondary ">Enviar Respuestas</button>
      </div>
    </form>

    <div class="row">
      <div class="col-12">
        <div class="alert alert-success m-1 d-flex justify-content-center" role="alert" *ngIf="showSuccesMessage">
          <strong>Excelente!</strong> Cambiando de lección...
        </div>
        <div class="alert alert-danger m-2" role="alert" *ngIf="serverErrorMessage">
          <strong> Error !</strong> {{ serverErrorMessage }}
        </div>
      </div>
    </div>

  </section>
</div>