import { Injectable } from '@angular/core';
import {HttpClient , HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class contactService {

    public url: string;
    noAuthHeader = {headers: new HttpHeaders({NoAuth: 'True'})};
    constructor(
        private _http: HttpClient
    ) {
        this.url = environment.apiBaseUrl;
    }

    sentEmail(data: {
        name: string,
        email: string,
        subject: string,
        message: string
    }) {
        return this._http.post(environment.apiBaseUrl + '/contact', data);
    }
}
