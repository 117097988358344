import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Answer } from 'src/app/models/answer';
import { User } from 'src/app/models/user';
import { answerService } from 'src/app/services/answer.service';
import { courseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-lesson-review',
  templateUrl: './lesson-review.component.html',
  styleUrls: ['./lesson-review.component.css'],
  providers: [courseService, UserService, answerService]
})
export class LessonReviewComponent implements OnInit {

  public user: User = {} as User;
  public numberOfLesson: number;
  public questions: any[] = [];

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _answerService: answerService,
    private _courseService: courseService,
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(
      params => {
        const idUser = params.idUser;
        this.numberOfLesson = params.numberOfLesson;

        this.getUserById(idUser);
      }
    );
  }

  getUserById(id: string) {
    this._userService.getUserById(id).subscribe(
      res => {
        if (res.user) {
          this.user = res.user;

          this.getQuestionsByNumberAndCourse(this.numberOfLesson, this.user.actualCourse);
        } else {
          this.router.navigate(['/']);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getQuestionsByNumberAndCourse(number, course) {
    this._answerService.getAnswers(this.user.email, course, number).subscribe(
      res => {
        if (res.answers && res.answers.length > 0) {
          const answers: any[] = res.answers;
          let question = [];
          
          answers.forEach(answer => {
            if (answer.status == "En Revisión") question.push(answer);
          });

          this.questions = question;
        }
          
      },
      err => {
        console.log(err);
      }
    );
  }

  qualify(id: string, correct: boolean) {
    for (let i = 0; i < this.questions.length; i++) {
      const element = this.questions[i];
      if (element._id == id) {
        this.questions[i].qualified = true;
        this.questions[i].observations = undefined;

        if (correct) this.questions[i].status = "Completado";
        else this.questions[i].status = "Corregir";
        
        break;
      }
    }
  }

  save() {
    let answers: Answer[] = [];

    for (let i = 0; i < this.questions.length; i++) {
      const element = this.questions[i];
      answers.push({
        question:     element.question,
        userEmail:    element.userEmail,
        status:       element.status,
        observations: element.observations
      } as Answer);
    }

    this._answerService.updateAnswers(answers as [Answer]).subscribe(
      res => {
        this._courseService.getCourseStatus(this.user.email, this.user.actualCourse).subscribe();
        setTimeout(() => {
          this.router.navigate(['/Admin-Panel']);
        }, 100);
      },
      err => {
        console.log(err);
      }
    );
  }

}
