import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { answerService } from 'src/app/services/answer.service';
import { courseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';

declare var $: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [UserService, courseService, answerService]
})
export class AdminComponent implements OnInit {
  public users: User[] = [];
  public userSelected: User = {} as User;
  public numberOfLessons: any[] = [];

  constructor(
    private router: Router,
    private _userService: UserService,
    private _courseService: courseService,
    private _answerService: answerService
  ) {
  }

  ngOnInit(): void {

    this.getUsers();
  }

  getUsers() {
    this._userService.getUsers().subscribe(
      res => {
        if (res.users) {
          let users: any[] = [];
          
          res.users.forEach(user => {
            if (user.actualCourse && user.actualCourse != '') {
              users.push(user);
            }
          });

          this.users = users;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  userSelect(user: User) {
    this.userSelected = user;
    this.numberOfLessons = [];

    if (user.actualCourse != "") {
      this._courseService.getCoursebyName(user.actualCourse).subscribe(
        response => {
          if (response.course) {
            this._courseService.getCourseStatus(user.email, user.actualCourse).subscribe(
              res => {
                if (res.lessons) this.numberOfLessons = res.lessons;
              },
              err => {
                console.log(err);
              }
            );
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  colorStatus(status: string): string {
    if (status === 'Completado') return 'text-success';
    if (status === 'En Revisión') return 'text-warning';
    if (status === 'Corregir') return 'text-danger';
    return 'text-secondary';
  }

  clickLesson(numberOfLesson: number, status: string) {
    if (status === 'En Revisión') {
      $('#lessonsModal').modal('hide');
      this.router.navigate(['Admin-Panel', 'Lesson-Review', this.userSelected._id, numberOfLesson]);
    }
  }

}
