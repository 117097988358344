import { Component, ElementRef, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { courseService } from '../../services/course.service';
import { environment } from '../../../environments/environment';
import { Course } from '../../models/courses';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User } from 'src/app/models/user';

declare var paypal;

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css'],
  providers: [courseService]
})
export class CourseDetailComponent implements OnInit {

  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;


  public course: Course;
  public precio: number;
  public nameCourse: string;
  public userDetails: User;
  public idCourse: string;
  display = true;

  constructor(private _courseService: courseService,
    private router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService) { }

  ngOnInit(): void {
    this._route.params.subscribe(
      params => {
        const id = params.id;
        this.idCourse = id;
        this.getCourse(id);
        console.log(id);
      }
    );

    if (this._userService.isLoggedIn() == true) {
      this._userService.getUserProfile().subscribe(
        res => {
          this.userDetails = res['user'];
        },
        err => {
          console.log(err);
        }
      );

      paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.nameCourse,
                  amount: {
                    currency_code: 'MXN',
                    value: this.precio
                  }
                }
              ]
            })
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            console.log(order);
            console.log(this.nameCourse);
            console.log(this.userDetails.email);
            this._userService.updateUserCourse(this.userDetails.email, this.nameCourse).subscribe(

              res => {
                location.href = "https://cursosporextension.com/My-Courses";
              },
              err => {
                console.log(err);
              }
            );

            this.updateLesson(this.userDetails.email, 1, this.course.numberOfLessons, 0);

          },
          onError: err => {
            console.log(err);
          }
        })
        .render(this.paypalElement.nativeElement);
    }
  }

  getCourse(id) {
    this._courseService.getCourse(id).subscribe(
      response => {
        this.course = response.course;
        this.precio = this.course.precio;
        this.nameCourse = this.course.name;
      },
      err => {
        console.log(err);
      });
  }

  isLogged() {
    return this._userService.isLoggedIn() == true;
  }

  updateLesson(email, lesson, finalLesson, progress) {
    this._userService.updateUserLesson(email, lesson, finalLesson, progress).subscribe(
      res => {
        console.log("Lesson updated");
      }
      , err => {
        console.log(err);
      }
    )
  }


  verifyActualCourse() {
    return this.userDetails.actualCourse != "";
  }

  signUp() {
    localStorage.setItem("courseSelected", this.idCourse);
    this.router.navigate(['Sign-Up']);
  }


  // test() {
  //   this._userService.updateUserCourse(this.userDetails.email, this.nameCourse).subscribe(
  //     res => { this.router.navigate(['/My-Courses']); },
  //     err => { console.log(err); }
  //   );
  //   this.updateLesson(this.userDetails.email, 1, this.course.numberOfLessons, 0);
  // }
}


