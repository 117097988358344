<div class="container">
    <h2 class="pt-4">Contáctenos </h2>
    <span> ¿Necesita ayuda o tiene una pregunta? </span>
    <section id="how_it_works">
        <label>Por favor, complete el siguiente formulario lo más específicamente posible. Esto nos ayudará a garantizar
            la respuesta más rápida y precisa posible.</label>
        <!-- Paso 1 -->
        <div class="row">
            <div class="col-lg-8 pt-4">
                <form (ngSubmit)="sent()">
                    <label for="name">Nombre </label>
                    <input type="text" class="form-control" id="name" placeholder="Nombre" name="name"
                        [(ngModel)]="data.name" required>

                    <label class="pt-3 " for="subject">Asunto</label>
                    <input type="text" class="form-control" id="subject" placeholder="Asunto" name="subject"
                        [(ngModel)]="data.subject" required>

                    <label class="pt-3 " for="email">Correo Electrónico</label>
                    <input type="text" class="form-control" id="email" placeholder="Correo Electrónico" name="email"
                        [(ngModel)]="data.email" required>

                    <label class="pt-3 " for="message">Mensaje</label>
                    <textarea class="form-control mb-3" id="message" rows="3" name="message"
                        [(ngModel)]="data.message"></textarea>

                    <div class="alert alert-success" role="alert" *ngIf="status == 'success'">
                        Enviado correctamante
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="status == 'error'">
                        <b>Error!</b> Verifica la información
                    </div>

                    <button type="submit" class="btn btn-outline-primary">Enviar</button>

                </form>

            </div>

            <div class="col-lg-4">
                <h4>Contactanos</h4>
                <img class="pt-4"
                    src="https://cursosporextension.com/assets/image/contact_3.jpg"
                    alt="">
                <p>
                    Cursos por extensión
                </p>
            </div>
        </div>
        <hr>
    </section>
</div>