<div class="container">
  <section id="Carrousel">
    <div id="carouselExampleSlidesOnly" class="carousel slide d-none d-md-block" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100" src="/assets/image/image_01_es_MX2.jpg" alt="First slide">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="/assets/image/image4_es_MX.jpg" alt="Second slide">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="/assets/image/image_09_es_MX2.jpg" alt="Third slide">
        </div>
      </div>
    </div>
    <p id="phrase" class="pt-4 font-italic"> “ Los cursos por correspondencia existen para que cualquiera que esté
      interesado en aprender los fundamentos de Dianética y Cienciología pueda hacerlo en su propio hogar.” – L. Ronald
      Hubbard </p>

    <hr class="my-4">
    <div id="firstRow" class="row">
      <div class="col-lg-3 col-sm-6">
        <h4>Consejo de la Semana</h4>
        <p class="tip-week">
          “Estaba teniendo problemas para entender uno de los capítulos de mi libro, así que llamé a mi supervisor y mi
          dijo que tomara un curso de estudio en línea…
        </p>
        <p class="tip-week d-none">
          “Estaba teniendo problemas para entender uno de los capítulos de mi libro, así que llamé a mi supervisor y mi
          dijo que tomara un curso de estudio en línea en la página web de los Ministros Voluntarios.

          “¡Lo hice y estoy tan feliz de haberlo hecho! Me dio una gran visión general de la Tecnología de Estudio, la
          cual me di cuenta que yo no estaba poniendo en práctica. El hacer este corto curso ha hecho mi progreso mucho
          más rápido y fácil. Se lo recomiendo a quien se esté moviendo lento o quien se siente confundido o
          desinteresado de alguna manera; este curso le va a dar las razones por las cuales tiene esos síntomas y cómo
          manejarlos.” – L.M.
        </p>

        <p class="text-primary click tip-week" (click)="clickTipWeek()">Read more...</p>
        <hr class="my-4">
      </div>
      <div class="col-lg-3  col-sm-6">
        <img src="/assets/image/image1.jpg" alt="">
        <br>
        <strong>Cómo funciona </strong>
        <em class="blockquote-footer">La experiencia del Curso por Extensión</em>
        Una descripción gráfica de la entrega del Curso por Extension
        <hr class="my-4">
      </div>

      <div class="col-lg-3 col-sm-6">
        <img src="/assets/image/image2.jpg" alt="">
        <br>

        <strong>Inscríbase en el Curso de Extensión</strong>
        <em class="blockquote-footer"> Y comience de una vez </em>
        Done para su siguiente curso y comience de una vez
        <hr class="my-4 d-none d-md-block">
      </div>
      <div class="col-lg-3 col-sm-6">
        <img src="/assets/image/invite_a_friend.jpg" alt="">
        <br>
        <strong>Invite a un Amigo </strong>
        <em class="blockquote-footer"> A tomar el Tour del Curso por Extensión </em>
        Dígale a sus amigos, familiares y asociados sobre el Curso por Extensión
      </div>
    </div>
    <hr class="my-4">
    <div id="secondRow" class="row">
      <div class="col-lg-3 col-sm-6">
        <h4>Historia de Éxito</h4>
        <p>“ Estando en la industria de la música me toca trabajar cualquier cantidad de horas inusuales y también vivo
          lejos de mi Org más cercana, por lo tanto me encanta ser un estudiante ‘en línea’…</p>
        <p> <a href="">Read more...</a> </p>
        <hr class="my-4">
      </div>
      <div class="col-lg-3 col-sm-6 ">
        <img src="/assets/image/faq.jpg" alt="">
        <br>
        <strong>Preguntas Frecuentes </strong>
        <em class="blockquote-footer"> En los Cursos por Correspondencia </em>
        Averigua aquí la respuesta a tus preguntas.
        <hr class="my-4">
      </div>
      <div class="col-lg-3 col-sm-6">
        <img src="/assets/image/books-mini-204X125[1].jpg" alt="">
        <br>

        <strong>Estudiantes del Cuadro de Honor</strong>
        <em class="blockquote-footer"> En el Curso por Extensión</em>
        Averigua quién está estudiando consecutivamente cada semana en el Curso por Extensión
        <hr class="my-4">
      </div>

      <div class="col-lg-3 col-sm-6">
        <img src="/assets/image/top_100_1[1].jpg" alt="">
        <br>
        <strong>La Lista de los Mejores 100 </strong>
        <em class="blockquote-footer">En el Curso por Extensión</em>
        Dígale a sus amigos, familiares y asociados sobre el Curso por Extensión
      </div>

    </div>

  </section>

</div>