<div class="container">
  <h2 class="pt-4">Información de Curso </h2>
  <span> {{course.name}} </span>
  <section id="how_it_works">
    <div class="row">
      <div class="col-lg-4">
        <img src="{{course.image}}" alt="">
      </div>
      <div class="col-lg-6 m-4">
        <h4>{{course.name}}</h4>
        <label>By {{course.author}}</label>
        <div class="pt-2">

          <button *ngIf="!isLogged()" type="button" class="m-2 btn btn-outline-primary btn-block" (click)="signUp()">
            Registrarse para comenzar este curso
          </button>

          <button *ngIf="isLogged() && !verifyActualCourse()" type="button"
            class="m-2  btn btn-outline-primary btn-block" data-toggle="modal" data-target="#exampleModal">
            Empezar Curso
          </button>

          <button *ngIf="isLogged() && verifyActualCourse()" type="button"
            class="m-2 btn btn-outline-primary btn-block" data-toggle="modal" data-target="#ModalCourse">
            Empezar Curso
          </button>

        </div>

        <!-- Modal PayPal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{course.name}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div #paypal></div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>

              </div>
            </div>
          </div>
        </div>


        <!-- Modal Curso -->
        <div class="modal fade" id="ModalCourse" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{course.name}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h5 *ngIf="isLogged()">Para adquirir el curso, finaliza : <strong>{{userDetails.actualCourse}}.</strong>
                </h5>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <label class="col-lg-3 "><strong>Categoría: </strong> {{course.name}}</label>
      <label class="col-lg-3"><strong>Donación: </strong> $ {{course.precio}} MXN </label>
      <label class="col-lg-4"><strong>Duración del curso: </strong> {{course.name}}</label>

      <h4 class="col-lg-12 pt-4">Descripción</h4>
      <p class="col-lg-12">{{course.description}}</p>
    </div>
  </section>
</div>