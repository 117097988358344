import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  showSuccesMessage: boolean;
  serverErrorMessage: String;
  constructor(public userService: UserService, private router: Router) { }

  model = {
    email: '',
    password: ''
  };

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {

    //Register 
    this.userService.postUser(form.value).subscribe(
      res => {
        //Login
        this.userService.login(form.value).subscribe(
          res => {
            this.userService.setToken(res['token']);
            this.showSuccesMessage = true;
            setTimeout(() => {
              this.showSuccesMessage = false;
              const courseSelected = localStorage.getItem('courseSelected');

              if (courseSelected == null) {
                this.router.navigate(['Courses']);
              } else {
                this.router.navigate(['Course', courseSelected]);
                localStorage.removeItem('courseSelected');
              }
              
            }, 4000);

          },
          err => {
            console.log(err);
            this.showSuccesMessage = true;
            setTimeout(() => this.showSuccesMessage = false, 4000);
          }
        );

      },
      err => {
        if (err.status == 422) {
          this.serverErrorMessage = err.error.join('<br/>');
        } else {
          this.serverErrorMessage = 'Algo salío mal, contactar a soporte';
        }
      }
    );

  }

  resetForm(form: NgForm) {
    this.userService.selectedUser = {} as User;
    form.resetForm();
    this.serverErrorMessage = '';
  }

}
