<div class="container">
  <h1 class="pt-4" *ngIf="userDetails">
    Bienvenido {{ userDetails.name }} {{ userDetails.lastname }}
  </h1>
  <h2>Sus Cursos</h2>
  <span>Administrar sus cursos de Scientology en Línea</span>

  <!--Prueba-->
  <section id="how_it_works">
    <div class="row">
      <div class="col-12 col-lg-3">
        <img class="mx-auto d-block" src="{{ course_id }} " alt="">

      </div>

      <div class="col-12 col-lg-7">
        <h4 *ngIf="!getCourse()">Curso Actual: <strong>{{ userDetails.actualCourse }}</strong></h4>
        <h2 *ngIf="getCourse()"> Actualmente no tiene ningun curso</h2>
        <span *ngIf="!getCourse()">Adquirido el día: <strong>{{ userDetails.initialDate }}</strong></span>

        <div class="progress m-4" *ngIf="!getCourse() && !correct">

          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            [ngStyle]="{ 'width': userDetails.progress + '%' }" aria-valuemin="0" aria-valuemax="100">
            {{ progressNoDecimal }}%
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="display && !correct" class="pt-4">
      <div class="d-flex align-items-center">
        <h3>Curso en Revisión</h3>
        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
      </div>
      <label>Se le notificará por correo cuando el supervisor revise sus respuestas</label>
    </div>

    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <a *ngIf="getCourse()" [routerLink]="['/Courses']">
          <button type="button" class="btn btn-outline-secondary">Catálogo de Cursos</button>
        </a>
      </div>
    </div>
    <hr>

    <h2 class="mt-4" *ngIf="!getCourse()">Lecciones</h2>
    <div [class]="'card mt-3' + (lesson.status == 'Sin Contestar' || lesson.status == 'Corregir' ? ' click' : '')"
      *ngFor="let lesson of numberOfLessons">
      <div class="card-body d-flex justify-content-between" (click)="clickLesson(lesson.number, lesson.status)">
        <span>Lección {{ lesson.number }}</span>
        <span [class]="colorStatus(lesson.status)">{{ lesson.status }}</span>
      </div>
    </div>

    <h2 class="my-4" *ngIf="coursesCompleted.length > 0">Cursos completados</h2>
    <div class="row" *ngFor="let course of coursesCompleted">
      <div class="col-12 col-lg-3">
        <img class="mx-auto d-block" src="{{ course.image }}">
      </div>
      <div class="col-12 col-lg-9">
        <h4>{{ course.name }}</h4>
        <span class="font-weight-bold text-success">Completado</span>
      </div>
    </div>

  </section>
</div>
