import { Component, OnInit } from '@angular/core';
import { courseService } from '../../services/course.service';
import { Course } from '../../models/courses';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
  providers: [courseService]
})

export class CoursesComponent implements OnInit {

  public courses: Course[];

  constructor(
    private _courseService: courseService
  ) { }

  ngOnInit(): void {
    this.getCourses();
  }

  getCourses() {
    this._courseService.getCourses().subscribe(
      res => {
        if (res.courses) {
          this.courses = res.courses;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  textFormat(text: string) {
    text = text.toLocaleLowerCase();
    text = text.substring(0, 1).toLocaleUpperCase() + text.substring(1, text.length);

    const search = text.search(':');
    if (search != -1) text = text.substring(0, search + 2) + 
      text.substring(search + 2, search + 3).toLocaleUpperCase() + 
      text.substring(search + 3, text.length);

    return text;
  }
}


