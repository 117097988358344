import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { courseService } from '../../services/course.service';
import { ActivatedRoute, Params } from '@angular/router';
import { answerService } from 'src/app/services/answer.service';
import { User } from 'src/app/models/user';
import { Course } from 'src/app/models/courses';

@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.css'],
  providers: [courseService, courseService, answerService]
})
export class MyCoursesComponent implements OnInit {

  public userDetails: User = {} as User;
  public name?: string;
  public course_id;
  public courseFinalLesson;
  public actualLesson;
  public display: boolean;
  public progressNoDecimal;
  public correct: boolean;
  public numberOfLessons: any[];
  public coursesCompleted: Course[] = [];

  constructor(public userService: UserService, private router: Router,
    private _route: ActivatedRoute,
    private _courseService: courseService,
    private _answerService: answerService
  ) { }

  ngOnInit() {

    this.userService.getUserProfile().subscribe(
      res => {
        this.userDetails = res['user'];
        this.name = this.userDetails.actualCourse;
        this.actualLesson = this.userDetails.actualNoLesson;
        if (!this.getCourse()) this.getCourseByName(this.name);
        this.noDecimal(this.userDetails.progress);
        this.toCorrect(this.userDetails.toCorrect);
        if (this.userDetails.previousCourses)
          this.getPreviousCourses(this.userDetails.previousCourses);
      },
      err => {
        console.log(err);
      }
    );
  }

  onLogOut() {
    this.userService.deleteToken();
    this.router.navigate(['Login']);
  }

  getCourseByName(name) {
    this._courseService.getCoursebyName(name).subscribe(
      response => {
        if (response.course) {
          this.course_id = response.course.image;
          this.courseFinalLesson = response.course.numberOfLessons;
          this.verifyCourse(this.actualLesson, this.courseFinalLesson);
          this.getStatusAnswers(name);
        }
      },
      err => {
        console.log(err);
      });
  }

  getPreviousCourses(courses: string[]) {
    this.coursesCompleted = [];

    courses.forEach(courseName => {
      this._courseService.getCoursebyName(courseName).subscribe(
        response => {
          if (response.course) this.coursesCompleted.push(response.course);
          console.log(this.coursesCompleted);
        },
        err => {
          console.log(err);
        }
      );
    });
  }

  getCourse() {
    return this.name == '';
  }

  getStatusAnswers(courseName: string) {
    this.numberOfLessons = [];
    this._courseService.getCourseStatus(this.userDetails.email, courseName).subscribe(
      res => {
        if (res.lessons) this.numberOfLessons = res.lessons;
      },
      err => {
        console.log(err);
      }
    );
  }

  colorStatus(status: string): string {
    if (status === 'Completado') return 'text-success';
    if (status === 'En Revisión') return 'text-warning';
    if (status === 'Corregir') return 'text-danger';
    return 'text-secondary';
  }

  clickLesson(numberOfLesson: number, status: string) {
    if (status === 'Corregir' || status === 'Sin Contestar') {
      this.router.navigate(['My-Course', numberOfLesson]);
    }
  }

  noDecimal(value) {
    this.progressNoDecimal = Math.trunc(value);
  }


  verifyCourse(actualLesson, finalLesson) {
    if (actualLesson > finalLesson && this.correct == false) {
      this.display = true;
      console.log("entra a verify");
    } else {
      this.display = false;
    }
  }

  toCorrect(toCorrect) {
    if (toCorrect) {
      this.correct = true;
    } else {
      this.correct = false;
    }
  }

}
