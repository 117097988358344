import { Component, OnInit } from '@angular/core';
import { contactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  providers: [contactService]
})
export class ContactComponent implements OnInit {
  public status = '';
  public data = {
    name: '',
    subject: '',
    email: '',
    message: ''
  }

  constructor(
    private _contactService: contactService,
  ) { }

  ngOnInit(): void {
  }

  sent() {
    if (
      this.data.name != '' &&
      this.data.subject != '' &&
      this.data.email != '' &&
      this.data.message != ''
    ) {
      this._contactService.sentEmail(this.data).subscribe(
        res => {
          if (res['response'] == true) {
            this.status = 'success';
            this.data.name = '';
            this.data.email = '';
            this.data.subject = '';
            this.data.message = '';
            
            console.log(res);
          } else {
            this.status = 'error';
          }
        },
        err => {
          this.status = 'error';
          console.log(err);
        }
      );
    } else {
      this.status = 'error';
    }
  }

}
