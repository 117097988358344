import { Component, OnInit } from '@angular/core';
import { courseService } from '../../services/course.service';
import { Course } from '../../models/courses';
import { Question } from '../../models/question';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service';
import { questionService } from '../../services/question.service';
import { NgForm } from '@angular/forms';
import { answerService } from '../../services/answer.service';
import { User } from 'src/app/models/user';
import { Answer } from 'src/app/models/answer';


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
  providers: [courseService, questionService, answerService]
})
export class QuestionsComponent implements OnInit {
  public course: Course;
  public question: Question;
  userDetails: User = {} as User;
  actualCourse: string;
  actualLesson: number;
  finalLesson: number;
  progress: number;
  inputTexts: string[] = [];
  public tempVal = {};
  answers: Answer[] = []
  answersToMyDummyQuestions: any = [];
  showSuccesMessage: boolean;
  serverErrorMessage: String;
  correct: boolean;
  displayLesson: boolean;
  numberOfLesson?: number;
  updateAnswers = false;
  public Questions: {
    question: string[]
  } = {
    question: []
  };

  jsonValue() {
    return this.answersToMyDummyQuestions;

  }

  onChildAnswer(questionIndex, answerValue) {
    const currentValue = this.answersToMyDummyQuestions[Number(questionIndex)];
    this.answersToMyDummyQuestions[Number(questionIndex)] = {
      ...currentValue,
      questionIndex: questionIndex + 1,
      answer: answerValue
    };
  }

  sendAnswers() {

    const answers = this.jsonValue().map(q => ({
      ...q,
      noLesson: this.numberOfLesson,
      courseName: this.userDetails.actualCourse,
      userEmail: this.userDetails.email,
      status: 'En Revisión'
    }))

    console.log(answers);
    

    if (this.updateAnswers) {
      this._answerService.updateAnswers(answers).subscribe(
        res => {
          this._router.navigate(['My-Courses']);
          console.log(answers);
          console.log("se enviaron respuestas")
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this._answerService.postAnswer(answers).subscribe(
        res => {
          console.log(answers);
          console.log("se enviaron respuestas")
        },
        err => {
          console.log(err);
        }
      );

      this.UpdateUserLesson(this.userDetails.email, +this.numberOfLesson + 1, this.finalLesson, this.progress);
    }
  }
  correctionDone() {
    this.userService.correctionDone(this.userDetails.email);
  }

  constructor(private _courseService: courseService,
    private _questionService: questionService,
    public _answerService: answerService,
    private _router: Router,
    private _route: ActivatedRoute, 
    public userService: UserService) { }

  ngOnInit(): void {
    this._route.params.subscribe(
      params => {
        const num = params.numberOfLesson;
        if (num) this.numberOfLesson = num;
      }
    );

    this.userService.getUserProfile().subscribe(
      res => {
        this.userDetails = res['user'];
        this.chooseLesson(this.userDetails.actualNoLesson);
        this.toCorrect(this.userDetails.toCorrect);
        this.actualCourse = this.userDetails.actualCourse;
        this.actualLesson = this.userDetails.actualNoLesson;
        this.progress = this.userDetails.progress;
        this.getCourseByName(this.actualCourse);

        if (!this.numberOfLesson) this.numberOfLesson = this.userDetails.actualNoLesson;
        this.getQuestionsByNumberAndCourse(this.numberOfLesson, this.userDetails.actualCourse);
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubmit(form: NgForm) {
    this._answerService.postAnswer(form.value).subscribe(
      res => {
        console.log("Values" + res);
      },
      err => {
        if (err.status == 422) {
          console.log(err);
        }
      });
  }

  //Método para traer las preguntas por lección y curso 
  getQuestionsByNumberAndCourse(number, course) {
    this._questionService.getQuestionsByNumberAndCourse(number, course).subscribe(
      response => {
        let Questions = response.question;

        this._answerService.getAnswers(this.userDetails.email, course, this.numberOfLesson).subscribe(
          res => {
            if (res.answers && res.answers.length > 0) {
              this.answers = res.answers;
              const responseQuestions: string[] = Questions.question;
              this.updateAnswers = true;
              let question = [];
              
              responseQuestions.forEach(rQuestion => {
                for (let i = 0; i < res.answers.length; i++) {
                  const answer = res.answers[i];
                  if (answer.question == rQuestion) {
                    if (answer.status == 'Corregir') question.push(rQuestion);
                    break;
                  }
                }
              });
              
              Questions = { question };
            }

            this.Questions = Questions;

            this.answersToMyDummyQuestions = this.Questions.question
              .map(questionString => ({ question: questionString }));
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );

  }

  observation(question) {
    for (let i = 0; i < this.answers.length; i++) {
      const answer = this.answers[i];
      if (answer.question == question) {
        if (answer.status == "Corregir" && answer.observations) {
          return "Observaciones: " + answer.observations;
        } else {
          break;
        }
      }
    }

    return '';
  }

  //Método de Actualizar Lección de Usuario 
  UpdateUserLesson(email, actualNoLesson, numberOfLessons, progress) {
    this.userService.updateUserLesson(email, actualNoLesson, numberOfLessons, progress).subscribe(
      res => {
        this.ShowMessages();
        if (actualNoLesson >= this.finalLesson + 1) {
          this._router.navigate(['My-Courses']);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  //Método de Mostrar Mensajes 
  ShowMessages() {
    this.showSuccesMessage = true;
    setTimeout(() => {
      this.showSuccesMessage = false,
        this.ngOnInit()
    }, 2000);
    
    this._router.navigate(['My-Courses']);
  }


  //Método para traer curso 
  getCourseByName(name) {
    this._courseService.getCoursebyName(name).subscribe(
      res => {
        this.finalLesson = res.course.numberOfLessons;
      },
      err => {
        console.log(err);
      }
    )
  }

  changeLesson(number) {
    this.getQuestionsByNumberAndCourse(number, this.actualCourse);
    this.userDetails.actualNoLesson = number;
    document.getElementById("questions").scrollIntoView({ behavior: "smooth" });
  }

  toCorrect(toCorrect) {
    if (toCorrect) {
      this.correct = true
    } else {
      this.correct = false
    }
  }

  chooseLesson(lesson) {
    if (lesson == 13) {
      this.displayLesson = true;
    }
    else {
      this.displayLesson = false;
    }
  }
}
