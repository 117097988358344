<div class="container">
  <h2 class="pt-4">Usuarios </h2>
  <section id="how_it_works" class="py-2">
    <div class="row">
      <div class="col-lg-12 pt-4 table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Correo</th>
              <th scope="col">Curso</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of users" class="click" data-toggle="modal" data-target="#lessonsModal"
              (click)="userSelect(user)">
              <th>{{ user.name }} {{ user.lastname }}</th>
              <td>{{ user.email }}</td>
              <td>{{ user.actualCourse }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>

  <!-- Modal -->
  <div class="modal fade" id="lessonsModal" tabindex="-1" aria-labelledby="lessonsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="lessonsModalLabel">{{ userSelected.name }} {{ userSelected.lastname }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div [class]="'card mt-2' + (lesson.status == 'En Revisión' ? ' click' : '')"
            *ngFor="let lesson of numberOfLessons">
            <div class="card-body d-flex justify-content-between" (click)="clickLesson(lesson.number, lesson.status)">
              <span>Lección {{ lesson.number }}</span>
              <span [class]="colorStatus(lesson.status)">{{ lesson.status }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>