<div class="container">
    <section id="login" class="d-none d-md-block">
        <div class="d-flex flex-row-reverse ">
            <a *ngIf="display==true" [routerLink]="['/Sign-Up']">Regístrate </a>
            <a *ngIf="display==true" [routerLink]="['/Login']">Iniciar Sesión </a>
            <a [routerLink]="['/']" *ngIf="isLogged()" (click)="LogOut()">Cerrar Sesión </a>
            <label *ngIf="isLogged()" class="text-white">{{userDetails.email}} </label>
        </div>
    </section>

    <section id="nav-bar">
        <div class="row">
            <div class="col-3">
                <div class="mr-auto p-2 "> <a [routerLink]="['/']">
                        <img src="https://cursosporextension.com/assets/image/scn-symbol-full-circle.png"
                            width="70px"></a>
                </div>
            </div>

            <div class="col-9 d-lg-none pt-4" style="text-align: right; ">
                <img class="d-lg-none " width="40px" style="margin-right: 1rem;"
                    src="https://cursosporextension.com/assets/bars-solid.svg" data-toggle="collapse"
                    data-target="#principal-menu">
            </div>

            <div class="p-md-4 col-sm-12 col-md-9">

                <div id="principal-menu" class="collapse">

                    <ul>
                        <li class="d-lg-none" *ngIf="display"><a [routerLink]="['/Login']" data-toggle="collapse"
                                data-target="#principal-menu"> <strong> Iniciar Sesión </strong></a></li>

                        <li class="d-lg-none" *ngIf="display"><a [routerLink]="['/Sign-Up']" data-toggle="collapse"
                                data-target="#principal-menu"> <strong>Registrarse</strong> </a></li>

                        <li *ngIf="display"><a [routerLink]="['/How_it_works']" data-toggle="collapse"
                                data-target="#principal-menu" data-toggle="collapse" data-target="#principal-menu">Cómo
                                funciona </a></li>

                        <li><a [routerLink]="['/Courses']" data-toggle="collapse" data-target="#principal-menu">Cursos
                            </a></li>

                        <li *ngIf="isLogged() && userDetails.role != 'Admin'"><a [routerLink]="['/My-Courses']"
                                data-toggle="collapse" data-target="#principal-menu">Mis Cursos </a></li>


                        <li *ngIf="isLogged() && userDetails.role != 'Admin'"><a [routerLink]="['/Contact']"
                                data-toggle="collapse" data-target="#principal-menu">Ayuda </a></li>

                        <li *ngIf="isLogged() && userDetails.role == 'Admin'"><a [routerLink]="['/Admin-Panel']"
                                data-toggle="collapse" data-target="#principal-menu">Usuarios</a></li>

                        <li><a [routerLink]="['/']" *ngIf="isLogged()" (click)="LogOut()" data-toggle="collapse"
                                data-target="#principal-menu">Cerrar Sesión </a></li>

                        <li class="d-md-none" *ngIf="isLogged()"> <a><strong>{{userDetails.email}}</strong> </a></li>


                        <li *ngIf="display"><a [routerLink]="['/Contact']" data-toggle="collapse"
                                data-target="#principal-menu">Contáctenos </a></li>

                    </ul>

                </div>
            </div>
        </div>